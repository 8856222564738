import React, { useState } from 'react';
import "../App.css";

const CourseChart = () => {
  // State to manage the hovered shape and clicked shape (including Capstone Projects)
  const [hoveredShape, setHoveredShape] = useState(null);
  const [clickedShape, setClickedShape] = useState(null); // Track the clicked shape
  const [clickedCourses, setClickedCourses] = useState(null); // Store courses for clicked shape

  const chartData = [
    {
      value: 50,
      color: '#ffd7d7',
      name: 'Software Engineering',
      courses: [
        { name: 'Agile Software Engineering', link: 'https://moduler.aau.dk/course/2022-2023/DSNDATFB512?lang=en-GB' },
        { name: 'Systems Development', link: 'https://moduler.aau.dk/course/2022-2023/DSNDATFB312?lang=en-GB' },
        { name: 'System Integration', link: 'https://kurser.dtu.dk/course/02291' },
        { name: 'Design and Evaluation of User Interfaces', link: 'https://moduler.aau.dk/course/2022-2023/DSNDATFB313?lang=en-GB' },
        { name: 'Internetworking and Web-programming', link: 'https://moduler.aau.dk/course/2022-2023/DSNDATFB212?lang=en-GB' },
        { name: 'Object Oriented Programming', link: 'https://moduler.aau.dk/course/2022-2023/DSNDATFB311?lang=da-DK' },
        { name: 'Imperative Programming', link: 'https://moduler.aau.dk/course/2022-2023/DSNDATFB105?lang=en-GB' },
        { name: 'Developing an Entrepreneurial Mindset', link: 'https://kurser.dtu.dk/course/38106' },
        { name: 'Innovation in Engineering', link: 'https://kurser.dtu.dk/course/42500' },
        { name: 'Problem Based Learning', link: 'https://moduler.aau.dk/course/2022-2023/TECHENGPBL20?lang=da-DK' },

      ]
    },
    {
      value: 60,
      color: '#dcd7ff',
      name: 'Algorithms & CS Theory',
      courses: [
        { name: 'Quantum Algorithms and Machine learning', link: 'https://kurser.dtu.dk/course/02195 ' },
        { name: 'Algorithmic Techniques for Modern Data Models', link: 'https://kurser.dtu.dk/course/02289' },
        { name: 'Probability Theory and Linear Algebra', link: 'https://moduler.aau.dk/course/2022-2023/22BMATSLIAL?lang=en-GB' },
        { name: 'Discrete Mathematics', link: 'https://moduler.aau.dk/course/2022-2023/B-MAT1-DTGA?lang=da-DK' },
        { name: 'Algorithms and Datastructures', link: 'https://moduler.aau.dk/course/2022-2023/DSNDATFB211?lang=da-DK' },
        { name: 'Algorithms and Computability', link: 'https://moduler.aau.dk/course/2022-2023/DSNSWB611?lang=en-GB' },
        { name: 'Logical Theories for Uncertainty and Learning', link: 'https://kurser.dtu.dk/course/02287' },
        { name: 'Syntax and Semantics', link: 'https://moduler.aau.dk/course/2022-2023/DSNDATFB412?lang=en-GB' },
        { name: 'Languages and Compilers', link: 'https://moduler.aau.dk/course/2022-2023/DSNDATFB411?lang=da-DK' },
        { name: 'Computer Architecture and Operating Systems', link: 'https://moduler.aau.dk/course/2022-2023/DSNDATFB413?lang=en-GB' },
        { name: 'Database Systems', link: 'https://moduler.aau.dk/course/2022-2023/DSNDATFB514?lang=en-GB' },
        { name: 'Models and Tools for Cyber Physical Systems', link: 'https://moduler.aau.dk/course/2022-2023/DSNSWB612?lang=en-GB' },

      ]
    },
    {
      value: 42.5,
      color: '#f6d7ff',
      name: 'AI, ML & Data Science',
      courses: [
        { name: 'Advanced Deep Learning in Computer Vision', link: 'https://kurser.dtu.dk/course/02501' },
        { name: 'Machine Learning for Signal Processing', link: 'https://kurser.dtu.dk/course/02471' },
        { name: 'Model-Based Machine Learning', link: 'https://kurser.dtu.dk/course/42186' },
        { name: 'Artificial Intelligence and Multi-Agent Systems', link: 'https://kurser.dtu.dk/course/02285' },
        { name: 'Machine Learning', link: 'https://moduler.aau.dk/course/2022-2023/DSNDATFB513?lang=en-GB' },
        { name: 'Image Analysis', link: 'https://kurser.dtu.dk/course/02502' },
        { name: 'Introduction to Deep Learning in Computer Vision', link: 'https://kurser.dtu.dk/course/02516' },
        { name: 'Process Mining', link: 'https://kurser.dtu.dk/course/02269' }
      ]
    },
    {
      value: 27.5,
      color: '#d7ffd8',
      name: 'Cybersecurity',
      courses: [
        { name: 'Biometric Systems', link: 'https://kurser.dtu.dk/course/02238' },
        { name: 'Research Topics in Cybersecurity', link: 'https://kurser.dtu.dk/course/02234' },
        { name: 'Applied Cryptography', link: 'https://kurser.dtu.dk/course/02232' },
        { name: 'Data Security', link: 'https://kurser.dtu.dk/course/02239' },
        { name: 'Security', link: 'https://moduler.aau.dk/course/2022-2023/DSNSWB613?lang=en-GB' },
      ]
    }
  ];
  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '0px',
  };

  const leftColumnStyle = {
    flex: 1,
    maxWidth: '40%',
    padding: '0',
    margin: '0',
    boxSizing: 'border-box',
  };

  const rightColumnStyle = {
    flex: 2,
    maxWidth: '60%',  // Ensure the right column uses all available space
    padding: '0',
    margin: '0',
    boxSizing: 'border-box',
  };

  const mobileStyle = {
    flex: 1,
    maxWidth: '100%',
    width: '100%',
  };

  const totalAngle = chartData.reduce((sum, item) => sum + item.value, 0);

  let startAngle = 0;
  const modifiedChartData = chartData.map(item => {
    const angle = (360 / totalAngle) * item.value;
    const updatedItem = {
      ...item,
      startAngle: startAngle, // Current startAngle
      angle: angle
    };

    // Update startAngle for next iteration
    startAngle += angle;

    return updatedItem;
  });

  // Function to create a pie chart slice
  const createChartSlice = (startAngle, angle, center, size, color, name, courses) => {
    const [cx, cy] = center;

    // Calculate the start and end angles in radians
    const startAngleRad = (Math.PI / 180) * startAngle;
    const endAngleRad = (Math.PI / 180) * (startAngle + angle);

    // Calculate the slice's path using the polar coordinates
    const x1 = cx + size * Math.cos(startAngleRad);
    const y1 = cy + size * Math.sin(startAngleRad);
    const x2 = cx + size * Math.cos(endAngleRad);
    const y2 = cy + size * Math.sin(endAngleRad);

    const largeArcFlag = angle > 180 ? 1 : 0;

    const pathData = `M ${cx} ${cy} L ${x1} ${y1} A ${size} ${size} 0 ${largeArcFlag} 1 ${x2} ${y2} Z`;

    return (
      <path
        d={pathData}
        fill={clickedShape === name ? 'var(--highlightText)' : hoveredShape === name ? 'var(--text)' : 'var(--highlightBackground)'} // Set fill to background
        stroke='var(--background)'
        strokeWidth="3" // Set stroke width
        onMouseEnter={() => setHoveredShape(name)}
        onMouseLeave={() => {
          if (clickedShape !== name) {
            setHoveredShape(null);
          }
        }}
        onClick={() => {
          setClickedShape(name); // Set clicked shape
          setClickedCourses(courses); // Set courses for clicked shape
        }}
        style={{
          cursor: 'pointer',
          transition: 'transform 0.3s ease, stroke 0.3s ease,  fill 0.3s ease, z-index 0.3s ease', // Smooth transition for scaling, border color, and z-index
          transform: (hoveredShape === name || clickedShape === name) ? 'scale(1.1)' : 'scale(1)', // Scale on hover or when clicked
          zIndex: hoveredShape === name ? 5 : 1, // Set z-index to 1 when hovered
        }}
      />
    );
  };

  // Function to create a ball
  const createBall = (center, size, color, name, courses) => {
    const [cx, cy] = center;

    return (
      <circle
        cx={cx}
        cy={cy}
        r={size}
        fill={clickedShape === name ? 'var(--highlightText)' : hoveredShape === name ? 'var(--text)' : 'var(--highlightBackground)'} // Set fill to background
        stroke='var(--background)'
        strokeWidth="3" // Set stroke width
        onMouseEnter={() => setHoveredShape(name)}
        onMouseLeave={() => {
          if (clickedShape !== name) {
            setHoveredShape(null);
          }
        }}
        onClick={() => {
          setClickedShape(name); // Set clicked shape
          setClickedCourses(courses); // Set courses for clicked shape
        }}
        style={{
          cursor: 'pointer',
          transition: 'transform 0.3s ease, stroke 0.3s ease,  fill 0.3s ease, z-index 0.3s ease', // Smooth transition for scaling, border color, and z-index
          transform: (hoveredShape === name || clickedShape === name) ? 'scale(1.1)' : 'scale(1)', // Scale on hover or when clicked
          zIndex: hoveredShape === name ? 5 : 1, // Set z-index to 1 when hovered
        }}
      />
    );
  };

  return (
    <div>
      <div
        style={containerStyle}
        className="container"
      >
        <div style={leftColumnStyle} className="left-column">
          <svg width="300" height="300" viewBox="0 0 300 300">
            <g transform="translate(150, 150)">
              {modifiedChartData.map((item, index) =>
                createChartSlice(item.startAngle, item.angle, [0, 0], 135, item.color, item.name, item.courses)
              )}
              {createBall([0, 0], 100, 'rgb(253, 255, 215)', 'Capstone Projects', [
                { name: 'Master Thesis', link: '' },
                { name: 'Bachelor Thesis', link: 'https://moduler.aau.dk/course/2022-2023/DSNSWB610?lang=en-GB' },
                { name: 'Design, Definition and Implementation of Programming Languages', link: 'https://moduler.aau.dk/course/2022-2023/DSNSWB410?lang=en-GB' },
                { name: 'Complex Backend-End Software', link: 'https://moduler.aau.dk/course/2022-2023/DSNSWB521?lang=en-GB' },
                { name: 'Well-Structured Application', link: 'https://moduler.aau.dk/course/2022-2023/DSNDATFB310?lang=en-GB' },
                { name: 'Larger Program Developed by a Group', link: 'https://moduler.aau.dk/course/2022-2023/DNSDATFB220?lang=en-GB' },
                { name: 'Program that Solves a Problem', link: 'https://moduler.aau.dk/course/2022-2023/DSNDATFB121?lang=en-GB' }

              ])}
              {/* Display the name of the hovered slice in the center */}
              {!(hoveredShape || clickedShape) && (
                <text
                  x="0"
                  y="0"
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  fontSize="calc(28px)"  // Adjusted to match the dynamic sizing fontSize="calc(25px + 2vmin)" 
                  fontFamily="Helvetica, sans-serif"
                  fill="var(--importantText)"
                  fontWeight="bold"  // Match thickness, set to "normal" or "bold"
                  style={{ pointerEvents: 'none', wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                >
                  <tspan x="0" dy="0">Educational</tspan>
                  <tspan x="0" dy="24">Profile</tspan>
                </text>
              )}

              {hoveredShape && (
                <text
                  x="0"
                  y="0"
                  textAnchor="middle"
                  alignmentBaseline="middle"
                  fontSize="calc(28px)"  // Adjusted to match the dynamic sizing fontSize="calc(25px + 2vmin)" 
                  fontFamily="Helvetica, sans-serif"
                  fill={hoveredShape === 'Capstone Projects' || clickedShape === 'Capstone Projects' ? 'var(--highlightBackground)' : 'var(--importantText)'}
                  fontWeight="bold"  // Match thickness, set to "normal" or "bold"
                  style={{ pointerEvents: 'none', wordWrap: 'break-word', whiteSpace: 'pre-line' }}
                >
                  {hoveredShape.split(' ').reduce((lines, word) => {
                    const currentLine = lines[lines.length - 1];
                    const estimatedWidth = (currentLine + word).length * 10; // Estimate character width
                    const ballDiameter = 85 * 2; // Diameter of the ball (radius * 2)
                    if (estimatedWidth > ballDiameter * 0.8) {
                      lines.push(word); // Start a new line if width exceeds diameter
                    } else {
                      lines[lines.length - 1] += ` ${word}`;
                    }
                    return lines;
                  }, ['']).map((line, index) => (
                    <tspan x="0" dy={index === 0 ? 0 : 24} key={index}>
                      {line.trim()}
                    </tspan>
                  ))}
                </text>
              )}
            </g>
          </svg>
        </div>
        <div style={rightColumnStyle} className="right-column">
          <div className="column">
            {/* Display the clicked courses */}
            {clickedCourses && Array.isArray(clickedCourses) ? (
              <div style={{ padding: 0, margin: 0 }}>
                <h2 style={{ padding: 0, margin: 0, color: "var(--titleText)" }}>{clickedShape === "Capstone Projects" ? "Capstone Project" : clickedShape} Courses</h2> {/* Display the name of the clicked shape */}
                <ol style={{ padding: 0, margin: 0 }}>
                  {clickedCourses.map((course, index) => (
                    <h3 key={index} style={{ margin: 0, padding: 0, color: "var(--titleText)" }}>
                      <li style={{ margin: 0, padding: 0, marginLeft: 25, color: "var(--titleText)" }}>
                        <h3 style={{ color: "var(--text)", margin: 0, padding: 0, marginLeft: 0 }}>
                          <a
                            href={course.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: "none",
                              color: "var(--text)",
                              transition: "color 0.3s", // Smooth transition for color change
                              padding: 0,
                              margin: 0
                            }}
                            onMouseEnter={(e) => (e.target.style.color = 'var(--highlightText)')} // Change color on hover
                            onMouseLeave={(e) => (e.target.style.color = 'var(--text)')} // Revert color on mouse leave
                          >
                            {course.name}
                          </a>
                        </h3>
                      </li>
                    </h3>
                  ))}
                </ol>
              </div>
            ) : <></>}
          </div>        </div>
        <style>
          {`
          @media (max-width: 768px) {
            .container {
              flex-direction: column;
            }

            .left-column, .right-column {
              ${Object.entries(mobileStyle)
              .map(([key, value]) => `${key}: ${value}`)
              .join('; ')}
            }
          }
        `}
        </style>
      </div>


    </div>
  );
};




export default CourseChart;
